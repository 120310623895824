import React from 'react';
import arrow from '../../assets/svg/up-arrow.svg';
import './ArrowToTop.scss';

import 'aos/dist/aos.css';

export default function ArrowToTop() {



    function scaleUpArrow(e) {
        e.currentTarget.id = "arrowHoverAnim1";
    }

    function scaleDownArrow(e) {
        e.currentTarget.id = "arrowHoverAnim2";
    }

    return (
        <div id="ArrowToTopContainer">
            <a href="#main">
                <img src={arrow} alt={arrow} onMouseEnter={scaleUpArrow} onMouseLeave={scaleDownArrow} data-aos="fade-up" data-aos-offset="800" data-aos-once="false" />
            </a>
        </div>
    );
}

