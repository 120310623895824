import React, { useLayoutEffect, useState } from 'react';
import './Skills.scss';

import 'aos/dist/aos.css';

import { DiHtml5 } from 'react-icons/di';
import { DiCss3 } from 'react-icons/di';
import { DiJavascript } from 'react-icons/di';
// import { FaAngular } from 'react-icons/fa';
import { GrGraphQl } from "react-icons/gr";
// import { FaReact } from 'react-icons/fa';
import { FaSass } from 'react-icons/fa';
import { FaNodeJs } from 'react-icons/fa';
import { FaVuejs } from 'react-icons/fa';
import { SiMongodb } from "react-icons/si";




export default function Skills() {

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [width, height] = useWindowSize();

    let iconSize;

    width > 700 ? iconSize = "7rem" : iconSize = "17vw";

    function scaleUpIcon(e) {
        e.currentTarget.id = "iconAnim1";
    }

    function scaleDownIcon(e) {
        e.currentTarget.id = "iconAnim2";
    }

    return (
        <div id="skillsContainer">

            <h4>My Main Stack</h4>

            <div data-aos="zoom-in" id="skillsIconsContainer">
                <DiHtml5 color="#e2521c" size={iconSize} title="HTML5" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                <DiCss3 color="#1973bb" size={iconSize} title="CSS3" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                <DiJavascript color="#e5a407" size={iconSize} title="Javascript" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                {/* <FaReact color="#00dbfc" size={iconSize} title="React JS" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} /> */}
                <FaVuejs color="#41b783" size={iconSize} title="Vue JS" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                <GrGraphQl color="#e535ab" size={iconSize} title="Graph QL" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                {/* <FaAngular color="#dd0031" size={iconSize} title="Angular" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} /> */}
                <FaSass color="#cd6699" size={iconSize} title="Sass" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                <FaNodeJs color="#9cce44" size={iconSize} title="Node JS" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
                <SiMongodb color="#4DB33D" size={iconSize} title="Mongo DB" className="skillIcon" onMouseEnter={scaleUpIcon} onMouseLeave={scaleDownIcon} />
            </div>
        </div>
    );
}

