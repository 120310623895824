import React from 'react';
import './Header.scss';

export default function Header() {

    return (
        <div id="main">
            <h1>
                <span className="titleLetter">Y</span>
                <span className="titleLetter">a</span>
                <span className="titleLetter">r</span>
                <span className="titleLetter">d</span>
                <span className="titleLetter">e</span>
                <span className="titleLetter">n</span>
                <span className="titleSpace">&nbsp; </span>
                <br className="titleBreak" />
                <span className="titleLetter">A</span>
                <span className="titleLetter">m</span>
                <span className="titleLetter">i</span>
                <span className="titleLetter">r</span>
            </h1>
            <h3>&lt; Front End Developer /&gt;</h3>
        </div>
    );
}

