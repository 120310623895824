import React from 'react';
import './App.scss';
import Main from './components/Main/Main';

// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   // Navigate
// } from 'react-router-dom';


function App() {

  return (
    // <Router>
    //   <Routes>
    //     <Route exact path="/" element={<Main contactSent={false} />}></Route>
    //     <Route exact path="/messageSent" element={<Main contactSent={true} />}></Route>
    //   </Routes>
    //   {/* <Navigate to="/" element={<Main />} /> */}
    // </Router>
    <Main />
  );
}

export default App;
