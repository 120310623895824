import React from 'react';
import './LightSwitch.scss';

export default function LightSwitch(props) {

    const changeDarkMode = props.changeDarkMode;

    function turnSwitch(e) {
        if (e.currentTarget.id === "lightSwitchTop") {
            document.getElementsByClassName("lightSwitchModeContainer")[0].id = "lightSwitchOff";
            changeDarkMode(false);
        }
        if (e.currentTarget.id === "lightSwitchBottom") {
            document.getElementsByClassName("lightSwitchModeContainer")[0].id = "lightSwitchOn";
            changeDarkMode(true);
        }
    }


    return (
        <div id="lightSwitch">
            <h3 className="lightSwitchTitle">Light Mode</h3>
            <div id="lightSwitchContainer">
                <div className="screw"></div>
                <div className="lightSwitchModeContainer">
                    <div className="lightSwitchMode" id="lightSwitchTop" onClick={turnSwitch}>
                        <div className="switchFace switchHidden"></div>
                        <div className="switchFace switchFront"><p>I</p></div>
                    </div>
                    <div className="lightSwitchMode" id="lightSwitchBottom" onClick={turnSwitch}>
                        <div className="switchFace switchFront"><p>O</p></div>
                        <div className="switchFace switchHidden"></div>
                    </div>
                </div>
                <div className="screw"></div>
            </div>
            <h3 className="lightSwitchTitle">Dark Mode</h3>
        </div>
    );
}

