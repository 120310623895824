import React from 'react';
import './Projects.scss';
import Project from '../Project/Project';
import { data } from './Data';

export default function Projects() {
    const projects = data.map((value, i) => {
        return <Project title={value.title} link={value.link} desktop={value.desktop} mobile={value.mobile} description={value.description} icons={value.icons} id={i} key={i} />
    });
    return (
        <div id="projects">
            <h4>Old Projects</h4>
            {projects}
        </div>
    );
}

