import React, { useState } from 'react';
import './Contact.scss';

// import {
//     // BrowserRouter as Router,
//     // Routes,
//     // Route,
//     useNavigate
// } from 'react-router-dom';


import ThankYou from './ThankYou/ThankYou';


export default function Contact(props) {

    // const contactSent = props.contactSent;

    // const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [contactSent, setContactSent] = useState(false);

    // const [messageSent, setMessageSent] = useState(false);


    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }


    // const cancelRedirect = () => {
    //     navigate("/")
    //     console.log("123123");
    // }


    function handleSubmit(e) {
        e.preventDefault();
        if (name === "") {
            document.getElementById("formName").classList.add("notValid");
            document.getElementById("formName").placeholder = "*Please enter your name";
        }
        else if (!formValidation(email)) {
            document.getElementById("formEmail").classList.add("notValid");
            document.getElementById("formEmail").placeholder = "*Please enter a valid email";
        }
        else {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", name, email, message })
            })
                .then((e) => console.log("Success!"))
                .catch(error => alert(error));
            setContactSent(true);
            // setMessageSent(true);
            // navigate("/messageSent");
        }

    }

    function formValidation(email) {
        const emailRegEx = /\S+@\S+\.\S+/;
        return emailRegEx.test(email);
    }


    function handleName(e) {
        setName(e.target.value);
        console.log(`name = ${name}`);
    }
    function handleEmail(e) {
        setEmail(e.target.value);
    }
    function handleMessage(e) {
        setMessage(e.target.value);
    }




    return (
        // <Router>
        <div id="contact">
            {/* <Routes> */}
            {/* <Route exact path="/"> */}
            <h1>Let's Talk!</h1>
            {!contactSent ? <form name="contact" id="contactForm" onSubmit={handleSubmit}>
                <div className="contactField">
                    <input type="text" name="name" placeholder="Name" id="formName" value={name} onChange={handleName} />
                </div>
                <div className="contactField">
                    <input type="email" name="email" placeholder="Email" id="formEmail" value={email} onChange={handleEmail} />
                </div>
                <div className="contactField">
                    <textarea name="message" placeholder="Your message" id="formText" value={message} onChange={handleMessage}></textarea>
                </div>

                <input type="submit" value="Send" id="formSubmitBtn" />

                {/* {messageSent ? <Navigate push to="/messageSent" /> : <Navigate to="/" />} */}
            </form>
                : <ThankYou />}
        </div>
    );
}

